<template>
  <div>
    <div class="public_class_box">
      <div class="public_class">
        <div class="consult_title">
          <div class="title_box">
            <img class="title_tag" src="@/assets/img/projectChannelPage/publicClass.png" alt="" />
            <span>公开</span>
            <span class="blue_text">课</span>
          </div>
          <div class="more" @click="openClassMore">更多</div>
        </div>
        <div class="content">
          <div class="item_box" v-for="(item, index) in openClassList" :key="index">
            <div class="item">
              <div class="time">
                <div>{{ item.zbBeginTime | format("MM-dd") }}</div>
                <div>{{ item.zbFinishTime | format("HH:mm") }}</div>
              </div>
              <div class="name">
                {{ item.name }}
              </div>
              <div class="btn" v-if="item.state == 2" @click="goOpenDetail(item)">
                观看回放
              </div>
              <div class="btn" v-else @click="goOpenDetail(item)">立即学习</div>
            </div>
            <div class="line" v-if="index + 1 != openClassList.length"></div>
          </div>
        </div>
      </div>
      <div class="consult">
        <div class="consult_title">
          <div class="title_box">
            <img class="title_tag" src="@/assets/img/projectChannelPage/consult.png" alt="" />
            <span>新闻</span>
            <span class="blue_text">资讯</span>
          </div>
          <div class="more" @click="goNext(`/allnews`)">更多</div>
        </div>
        <div class="consult_content">
          <div class="consult_img">
            <div class="img_box m-b-20">
              <img v-if="newsList && newsList.length > 0" class="img" :src="newsList[0].img" alt=""
                @click="allnewxq(newsList[0])" />
            </div>
            <div class="img_box">
              <img v-if="newsList && newsList.length > 1" class="img" :src="newsList[1].img" alt=""
                @click="allnewxq(newsList[1])" />
            </div>
          </div>
          <div class="consult_text">
            <div class="consult_text_item" v-for="(item, index) in newsList" :key="index" v-show="index > 1"
              @click="allnewxq(item)">
              <div class="round"></div>
              <div class="content">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="teacher">
        <div class="consult_title">
          <div class="title_box">
            <img class="title_tag" src="@/assets/img/projectChannelPage/teacher.png" alt="" />
            <span>授课</span>
            <span class="blue_text">老师</span>
          </div>
          <div class="more" @click="teacherMore">更多</div>
        </div>
        <div class="teacher_box">
          <div class="teacher_item" v-for="(item, index) in teacherList" :key="index"
            @click="goNext(`/faculty/teacherDetail?id=${item.id}`)">
            <!-- <img class="img" :src="item.terImg" alt="" /> -->
            <div class="img_box">
              <el-image class="img" :src="item.terImg" fit="cover"></el-image>
            </div>
            <div class="name_box">
              <div class="name">
                <span>{{ item.userName }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{ item.majorName }}</span>
              </div>
              <div class="tips_box">
                <div class="tips" v-html="ImgSizeLimit(item.profile)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryAllArticle } from "@/api/home.js";
import { TeacherClient } from "@/api/teacherClient";
let teacherClient = new TeacherClient();
import { Know } from "@/api/know";
let know = new Know();
import { getDomain, getInfo } from "@/api/cookies";
import Bus from "@/api/bus";

export default {
  props: ["pKind"],
  data() {
    return {
      newsList: [],
      teacherList: [],
      openClassList: [],
      entrantsType: null,
      userInfo: null,
    };
  },
  created() {
    this.userInfo = getInfo();
    this.entrantsType = getDomain().entrantsType;
    this.queryAllArticle();
    this.getTeacherList();
    this.getOpenClassList();
  },
  methods: {
    // 资讯
    queryAllArticle() {
      queryAllArticle(this.pKind, 10, 1).then((res) => {
        this.newsList = res.rows;
      });
    },
    // 资讯详情
    allnewxq(index) {
      let routeUrl = this.$router.resolve({
        name: "新闻资讯详情",
        params: { id: index.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 讲师列表
    getTeacherList() {
      teacherClient.teacherPageList(4, 1, undefined, 1).then((res) => {
        if (res.rows) {
          this.teacherList = res.rows;
          this.total = res.total;
        } else {
          this.teacherList = [];
        }
      });
    },
    // 公开课列表
    getOpenClassList() {
      let data = {
        pageSize: 9999,
        pageNum: 1,
        kindId: this.pKind,
      };
      know.getPublicityClassList(data).then((res) => {
        this.openClassList = res.rows;
      });
    },
    // 师资力量详情
    teacherMore() {

      let routeUrl;
      switch (this.entrantsType) {
        case 1:
          routeUrl = this.$router.resolve({
            path: "/facultysixth",
          });
          break;
        case 2:
          routeUrl = this.$router.resolve({
            path: "/faculty",
          });
          break;
        case 5:
          routeUrl = this.$router.resolve({
            path: "/facultyPartner",
          });
          break;
        default:
          routeUrl = this.$router.resolve({
            path: "/faculty",
          });
      }
      window.open(routeUrl.href, "_blank");
    },
    openClassMore() {
      let routeUrl;
      switch (this.entrantsType) {
        case 1:
          routeUrl = this.$router.resolve({
            path: "/publicClasssixth",
          });
          break;
        case 2:
          routeUrl = this.$router.resolve({
            path: "/publicClass",
          });
          break;
        case 3:
          routeUrl = this.$router.resolve({
            path: "/seventhPage/publicClass",
          });
          break;
        case 5:
          routeUrl = this.$router.resolve({
            path: "/partnerPage/publicClass",
          });
          break;
      }
      window.open(routeUrl.href, "_blank");
    },
    goOpenDetail(item) {
      if (this.userInfo) {
        if (item.state == 2 && !item.playbackUrl) {
          this.$message({
            type: "error",
            message: "回放生成中",
          });
        } else {
          this.addRecord(item);
          let routeUrl = this.$router.resolve({
            path: "/publicClass/publicClassDetail",
            query: {
              id: item.id,
            },
          });
          window.open(routeUrl.href, "_blank");
        }
      } else {
        Bus.$emit("toLoginPage", true);
      }
    },
    /* 点击公开课添加观看记录 */
    addRecord(item) {
      let data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null,
      };
      know.addPublicityClassStudentRecord(data).then((res) => { });
    },
  },
};
</script>

<style lang="less" scoped>
.public_class_box {
  margin-top: 19px;
  height: 410px;
  display: flex;

  .public_class {
    width: 287px;
    height: 410px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-right: 19px;

    .consult_title {
      margin-bottom: 21px;
    }

    .content {
      height: 300px;
      overflow: auto;

      .item_box {
        .item {
          display: flex;
          align-items: center;

          .time {
            color: #4a6af0;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            margin-right: 14px;

            div {
              white-space: nowrap;
            }
          }

          .name {
            width: 110px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #6a6a6a;
            font-size: 14px;
            line-height: 21px;
          }

          .img_item {}

          .btn {
            width: 76px;
            height: 26px;
            border-radius: 14px;
            border: 1px #4a6af0 solid;
            color: #4a6af0;
            font-size: 12px;
            text-align: center;
            line-height: 26px;
            cursor: pointer;
          }
        }

        .line {
          width: 1px;
          height: 36px;
          background-color: #707070;
          opacity: 0.34;
          margin-left: 17px;
        }
      }
    }
  }

  .consult {
    width: 598px;
    height: 410px;
    border-radius: 8px;
    background-color: #ffffff;
    margin-right: 18px;
    padding: 21px 27px 22px 23px;

    .consult_title {
      margin-bottom: 21px;
    }

    .consult_content {
      display: flex;

      .consult_img {
        margin-right: 37px;

        .img_box {
          width: 233px;
          height: 146px;

          .img {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }

      .consult_text {
        /* overflow: auto; */
        height: 312px;

        .consult_text_item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          cursor: pointer;

          .round {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #b4b4b4;
            opacity: 0.58;
            margin-right: 16px;
          }

          .content {
            color: #484848;
            width: 252px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .teacher {
    width: 298px;
    height: 410px;
    border-radius: 8px;
    background-color: #ffffff;

    .consult_title {
      margin-bottom: 16px;
    }

    .teacher_box {
      height: 310px;

      .teacher_item {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        cursor: pointer;

        .img_box {
          width: 53px;
          height: 53px;
          border-radius: 40px;
          margin-right: 10px;
          overflow: hidden;

          .img {
            width: 53px;
          }
        }

        .name_box {
          width: 170px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .name {
            color: #6a6a6a;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 9px;
          }

          .tips_box {
            width: 170px;
            height: 16px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            .tips {
              height: 16px;
              color: #979797;
              font-size: 12px;
              width: 170px;

              ::v-deep p {
                width: 170px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              ::v-deep div {
                width: 170px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

        }
      }
    }
  }

  .public_class,
  .consult,
  .teacher {
    padding: 21px 27px 22px 23px;

    .consult_title {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px rgba(112, 112, 112, 0.2) solid;

      .title_box {
        display: flex;
        align-items: flex-start;
        font-weight: 600;
        line-height: 24px;
        height: 40px;
        width: 100px;
        border-bottom: 2px solid #4a6af0;

        .title_tag {
          width: 15px;
          height: 15px;
          margin-right: 10px;
          margin-top: 4px;
        }

        .blue_text {
          color: #4a6af0;
        }
      }

      .more {
        color: #b4b4b4;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
      }
    }
  }
}
</style>