<template>
  <div>
    <div class="single_subject">
      <div class="title_box">
        <div class="title_left">
          <div class="bule_block"></div>
          <span class="name">单科课程</span>
        </div>
        <el-dropdown>
          <div class="filter">
            <img
              class="img"
              src="@/assets/img/projectChannelPage/filter.png"
              alt=""
            />
            <span v-if="!zyName">专业筛选</span>
            <span v-else>{{ zyName }}</span>
          </div>
          <el-dropdown-menu  slot="dropdown">
            <div class="dropdown"></div>
            <el-dropdown-item @click.native="allCourse">全部</el-dropdown-item>
            <el-dropdown-item
              v-for="(item, index) in zyList"
              :key="index"
              @click.native="chooseZy(item)"
              >{{ item.zName }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="table">
        <el-table
          :data="courseList"
          border
          height="349"
          row-key="id"
          @selection-change="changeCourse"
        >
          <el-table-column
            :reserve-selection="true"
            type="selection"
            width="57"
          ></el-table-column>
          <el-table-column
            label="课程名称"
            prop="title"
            width="821"
            align="center"
          ></el-table-column>
          <el-table-column
            label="课时"
            prop="allTime"
            width="151"
            align="center"
          ></el-table-column>
          <el-table-column
            label="价格"
            prop="price"
            width="140"
            align="center"
          ></el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          @size-change="courseSizeChange"
          @current-change="courseCurrentChange"
          :current-page="page.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
      <div class="price_box">
        <div class="price">合计￥{{ getPrice }}</div>
        <div class="btn" @click="addOrder">提交订单</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
import { OrderClient } from "@/api/orderClient";
let orderClient = new OrderClient();
let know = new Know();
import { getInfo } from "@/api/cookies";
import Bus from "@/api/bus";

export default {
  props: ["zyList", "pKind"],
  data() {
    return {
      zyId: null,
      zyName: "",
      courseList: [],
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      selectedCourse: [],
      userInfo: null
    };
  },
  created() {
    this.userInfo = getInfo()
    this.getCourseList();
  },
  computed: {
    getPrice() {
      let totalPrice = 0;
      this.selectedCourse.forEach((item) => {
        totalPrice += Number(item.price);
      });
      return totalPrice;
    },
  },
  methods: {
    allCourse() {
      this.zyId = null;
      this.zyName = "";
      this.getCourseList();
    },

    // 单科课程
    getCourseList() {
      know
        .getCourseList(
          this.page.pageSize,
          this.page.pageNum,
          undefined,
          undefined,
          this.pKind,
          this.zyId
        )
        .then((res) => {
          this.courseList = res.rows;
          this.page.total = res.total;
        });
    },

    // 选择课程
    changeCourse(val) {
      this.selectedCourse = val;
    },

    addOrder() {
      if (!this.userInfo) {
        Bus.$emit("toLoginPage", true);

        return
      }
      if (!this.selectedCourse || this.selectedCourse.length == 0) return;
      let ids = this.selectedCourse.map((item) => {
        return item.id;
      });
      let commodityName = this.selectedCourse.map((item) => {
        return item.title;
      });
      let commodityType = this.selectedCourse.map((item) => {
        switch (item.types) {
          case 1:
            item.type = 2;
            break;
          case 2:
            item.type = 3;
            break;
          case 3:
            item.type = 7;
            break;
          case 4:
            item.type = 8;
            break;
          case 5:
            item.type = 10;
            break;
        }
        return item.type;
      });
      orderClient
        .addOrders(
          ids,
          getInfo().id,
          commodityName,
          commodityType,
          this.selectedCourse[0].title,
          Number(this.pKind)
        )
        .then((res) => {
          if (res.code == 0) {
            this.$router.push({
              name: "个人中心订单页",
              params: { orderNumber: res.msg },
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    chooseZy(item) {
      this.zyId = item.zId;
      this.zyName = item.zName;
      this.getCourseList();
    },

    courseSizeChange(val) {
      this.page.pageSize = val;
      this.getCourseList();
    },

    courseCurrentChange(val) {
      this.page.pageNum = val;
      this.getCourseList();
    },
  },
};
</script>

<style lang="less" scoped>
.single_subject {
  width: 100%;
  height: 602px;
  border-radius: 17px;
  background-color: #ffffff;
  margin-top: 19px;
  padding: 28px 24px 26px 24px;
  .title_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
    .title_left {
      display: flex;
      align-items: center;
      .bule_block {
        width: 4px;
        height: 19px;
        background-color: #4a6af0;
        margin-right: 12px;
      }
      .name {
        color: #484848;
        font-size: 23px;
        font-weight: 600;
      }
    }
    .filter {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 147px;
      padding: 0 10px;
      height: 35px;
      background-color: #f8f8f8;
      border-radius: 21px;
      color: #4a6af0;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;

      .img {
        margin-right: 16px;
      }
    }
  }
  .price_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 22px;
    .price {
      color: #484848;
      font-size: 20px;
      font-weight: 600;
      margin-right: 34px;
    }
    .btn {
      width: 131px;
      height: 38px;
      border-radius: 24px;
      background-color: rgba(255, 94, 81, 0.16);
      color: #ff5e51;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      line-height: 38px;
      cursor: pointer;
    }
  }
  .pagination {
    margin-top: 20px;
  }
}
::v-deep.el-dropdown-menu{
  width: 147px;
  height: 300px !important;
  overflow: auto;
  text-align: center;
}
</style>