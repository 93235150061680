import { render, staticRenderFns } from "./singleSubjectCourse.vue?vue&type=template&id=047fb6b8&scoped=true&"
import script from "./singleSubjectCourse.vue?vue&type=script&lang=js&"
export * from "./singleSubjectCourse.vue?vue&type=script&lang=js&"
import style0 from "./singleSubjectCourse.vue?vue&type=style&index=0&id=047fb6b8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "047fb6b8",
  null
  
)

export default component.exports