<template>
  <div>
    <div class="take_an_exam material">
      <div class="title_box">
        <div class="title_left">
          <div class="bule_block"></div>
          <span class="name">资料</span>
        </div>
        <el-dropdown>
          <div class="filter">
            <img
              class="img"
              src="@/assets/img/projectChannelPage/filter.png"
              alt=""
            />
            <span v-if="!zyName">专业筛选</span>
            <span v-else>{{ zyName }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="allCourse">全部</el-dropdown-item>
            <el-dropdown-item
              v-for="(item, index) in zyList"
              :key="index"
              @click.native="chooseZy(item)"
              >{{ item.zName }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="content_box">
        <div class="btn_box">
          <div
            class="btn_item"
            :class="typeId == item.value ? 'on' : ''"
            v-for="(item, index) in btnList"
            :key="index"
            @click="chooseType(item)"
          >
            <img
              v-show="typeId == item.value"
              class="leftTriangle"
              src="@/assets/img/projectChannelPage/triangle1.png"
              alt=""
            />
            <img
              v-show="typeId == item.value"
              class="rightTriangle"
              src="@/assets/img/projectChannelPage/triangle2.png"
              alt=""
            />
            <img class="img" :src="item.img" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="content">
          <div
            class="content_item"
            v-for="(item, index) in materialList"
            :key="index"
          >
            <div class="round"></div>
            <div class="name">
              {{ item.title }}
            </div>
            <el-button
              type="text"
              @click="goDetail(item)
                
              "
              >阅读</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataClient } from "@/api/dataClient";
import { getInfo } from "@/api/cookies";
import Bus from "@/api/bus";
let dataClient = new DataClient();
export default {
  props: ["zyList", "pKind", "schoolId"],
  data() {
    return {
      btnList: [
        {
          name: "辅导讲义",
          img: require("@/assets/img/projectChannelPage/handout.png"),
          value: 81,
        },
        {
          name: "范文专区",
          img: require("@/assets/img/projectChannelPage/modelEssay.png"),
          value: 82,
        },
        {
          name: "串讲资料",
          img: require("@/assets/img/projectChannelPage/crossTalk.png"),
          value: 83,
        },
        {
          name: "复习资料",
          img: require("@/assets/img/projectChannelPage/crossTalk.png"),
          value: 84,
        },
      ],
      typeId: 81,
      zyId: null,
      zyName: "",
      materialList: [],
      userInfo: null,
    };
  },
  created() {
    this.userInfo = getInfo();
    this.getList();
  },
  methods: {
    getList() {
      dataClient
        .getPackageList(
          9999,
          1,
          this.pKind,
          this.zyId,
          this.typeId,
          this.userInfo ? this.userInfo.id : null
        )
        .then((res) => {
          this.materialList = res.rows;
        });
    },
    chooseType(item) {
      this.typeId = item.value;
      this.getList();
    },
    allCourse() {
      this.zyId = null;
      this.zyName = "";
      this.getList();
    },
    chooseZy(item) {
      this.zyId = item.zId;
      this.zyName = item.zName;
      this.getList();
    },
    goDetail(item) {
      if (!this.userInfo) {
        Bus.$emit("toLoginPage", true);
        return
      }
      this.$router.push({
        path:  `/data/materialPage?id=${item.id}&title=${item.title}&schoolId=${this.schoolId}&isPurchase=${item.isPurchase}&num=${item.price}`,
      })
    }
  },
};
</script>

<style lang="less" scoped>
.take_an_exam {
  width: 100%;
  height: 324px;
  border-radius: 17px;
  background-color: #ffffff;
  margin-top: 19px;
  padding: 28px 24px 26px 24px;
  .title_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .title_left {
      display: flex;
      align-items: center;
      .bule_block {
        width: 4px;
        height: 19px;
        background-color: #4a6af0;
        margin-right: 12px;
      }
      .name {
        color: #484848;
        font-size: 23px;
        font-weight: 600;
      }
    }
    .filter {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 147px;
      height: 35px;
      background-color: #f8f8f8;
      border-radius: 21px;
      color: #4a6af0;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      padding: 0 10px;

      .img {
        margin-right: 16px;
      }
    }
  }
  .content_box {
    display: flex;
    align-items: flex-start;
    .btn_box {
      margin-left: 47px;
      margin-right: 105px;
      .btn_item {
        width: 241px;
        height: 42px;
        border-radius: 23px;
        background-color: #f1f1f1;
        color: #b4b4b4;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;
        .img {
          margin-right: 12px;
        }
        .leftTriangle {
          left: -30px;
          position: absolute;
        }
        .rightTriangle {
          right: -30px;
          position: absolute;
        }
      }
      .on {
        color: #ffffff;
        background-color: #4a6af0;
      }
    }
    .content {
      width: 778px;
      max-height: 200px;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      .content_item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-right: 20px;
        height: 21px;
        .round {
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #b4b4b4;
          margin-right: 15px;
        }
        .name {
          width: 280px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 35px;
        }
      }
    }
  }
}
::v-deep.el-dropdown-menu{
  width: 147px;
  height: 300px !important;
  overflow: auto;
  text-align: center;
}
</style>