<template>
  <div>
    <div class="layout_box">
      <div class="projectTag_box">
        <div class="tag_backImg_box">
          <div class="name">{{ projectDetails.pname }}</div>
        </div>
        <div class="tag_box">
          <div class="left_tag">
            <div v-for="(item, index) in leftTag.slice(0, 6)" :key="index" class="tag_item"
              :class="index == 2 || index == 5 ? '' : 'm-r-38'">
              <span class="icon" :class="icon[index].img" />
              <span>{{ item.name }}</span>
              <div class="name_box" @click="goPracticalInformation(null, item)">
                {{ item.menuName }}
              </div>
            </div>
          </div>
          <el-divider class="line" direction="vertical" />
          <div class="left_tag">
            <div v-for="(item, index) in middleTag" :key="index" class="tag_item"
              :class="index == 2 || index == 5 ? '' : 'm-r-38'" @click="goTakeAnExam(item)">
              <img class="icon" :src="item.img" alt="">
              <span>{{ item.name }}</span>
            </div>
          </div>
          <el-divider class="line" direction="vertical" />
          <div class="right_tag">
            <div v-for="(item, index) in rightTag" :key="index" class="tag_item"
              :class="index == 1 || index == 3 ? '' : 'm-r-38'" @click="goMaterial(item)">
              <img class="icon" :src="item.img" alt="">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>

      <class-comparison :menu-list="menuList" :project-details="projectDetails" :zy-list="zyList" :days="days"
        :p-kind="pKind" :icon="icon" />

      <!-- 公开课、新闻资讯、授课老师 -->
      <open-class-information-teacher v-if="projectDetails.classNewsInstructor == 1" :p-kind="pKind" />
      <!-- 班型对比 -->
      <comparison v-if="projectDetails.classesContrast == 1" style="margin-top: 23px" :p-kind="pKind"
        :school-id="schoolId" />
      <!-- :school-id="schoolId" -->
      <!-- 单科课程 -->
      <single-subject-course v-if="projectDetails.singleSubject == 1" :p-kind="pKind" :zy-list="zyList" />
      <!-- 考试试题 -->
      <take-an-exam v-if="projectDetails.question == 1" :p-kind="pKind" :zy-list="zyList" />
      <!-- 资料 -->
      <material v-if="projectDetails.doc == 1" :p-kind="pKind" :zy-list="zyList" :school-id="schoolId" />
      <!--  -->
    </div>
  </div>
</template>

<script>
import { selectChannel, selectPcList, selectZyList } from '@/api/home.js'
import openClassInformationTeacher from './components/openClassInformationTeacher.vue'
import singleSubjectCourse from './components/singleSubjectCourse.vue'
import classComparison from './components/classComparison.vue'
import takeAnExam from './components/takeAnExam.vue'
import material from './components/material.vue'
import comparison from './components/comparison.vue'
import { getDomain } from '@/api/cookies'

export default {
  components: {
    openClassInformationTeacher,
    singleSubjectCourse,
    takeAnExam,
    material,
    classComparison,
    comparison
  },
  data() {
    return {
      pKind: '',
      leftTag: [
        /* {
          name: "报名时间",
          img: require("@/assets/img/projectChannelPage/registrationTime.png"),
        },
        {
          name: "报考条件",
          img: require("@/assets/img/projectChannelPage/applicationConditions.png"),
        },
        {
          name: "专业要求",
          img: require("@/assets/img/projectChannelPage/require.png"),
        },
        {
          name: "报考流程",
          img: require("@/assets/img/projectChannelPage/process.png"),
        },
        {
          name: "报考科目",
          img: require("@/assets/img/projectChannelPage/suject.png"),
        },
        {
          name: "常见问题",
          img: require("@/assets/img/projectChannelPage/question.png"),
        }, */
      ],
      icon: [
        {
          img: 'el-icon-notebook-2'
        },
        {
          img: 'el-icon-c-scale-to-original'
        },
        {
          img: 'el-icon-date'
        },
        {
          img: 'el-icon-edit-outline'
        },
        {
          img: 'el-icon-document-copy'
        },
        {
          img: 'el-icon-tickets'
        }
      ],
      middleTag: [
        {
          name: 'VIP试题',
          img: require('@/assets/img/projectChannelPage/vip.png'),
          value: 41
        },
        {
          name: '考点练习',
          img: require('@/assets/img/projectChannelPage/testCenter.png'),
          value: 40
        },
        {
          name: '易错考题',
          img: require('@/assets/img/projectChannelPage/errorProne.png'),
          value: 61
        },
        {
          name: '考前押题',
          img: require('@/assets/img/projectChannelPage/suject.png'),
          value: 62
        },
        {
          name: '模拟试卷',
          img: require('@/assets/img/projectChannelPage/testCenter.png'),
          value: 37
        },
        {
          name: '历年真题',
          img: require('@/assets/img/projectChannelPage/realQuestion.png'),
          value: 38
        }
      ],
      rightTag: [
        {
          name: '辅导讲义',
          img: require('@/assets/img/projectChannelPage/handout.png'),
          value: 81
        },
        {
          name: '范文专区',
          img: require('@/assets/img/projectChannelPage/modelEssay.png'),
          value: 82
        },
        {
          name: '串讲资料',
          img: require('@/assets/img/projectChannelPage/crossTalk.png'),
          value: 83
        },
        {
          name: '复习资料',
          img: require('@/assets/img/projectChannelPage/crossTalk.png'),
          value: 84
        }
      ],
      projectDetails: {},
      courseList: [],
      bannerImg: [],
      courseList: [],
      zyList: [],
      entrantsType: null,
      schoolId: null,
      flatArr: [],
      menuList: [],
      days: []
    }
  },
  created() {
    this.entrantsType = getDomain().entrantsType
    this.pKind = this.$route.query.id
    this.schoolId = this.$route.query.schoolId
    this.getDetails()
    this.selectPcList()

    this.selectZyList()
  },
  methods: {
    // 项目详情
    getDetails() {
      const data = {
        pKind: this.pKind
      }
      selectChannel(data)
        .then((res) => {
          if (res.code == 0) {
            this.projectDetails = res.data

          }
        })
        .then(() => {
          this.getTime()
        })
    },
    // 计算距离考试时间
    getTime() {
      const target = new Date(
        this.projectDetails.examTime.toString().replace(/-/g, '/')
      ).getTime()
      const nowTime = new Date().getTime()
      if (target < nowTime) return
      const days = Math.ceil((target - nowTime) / 1000 / 60 / 60 / 24)
        .toString()
        .split('')

      switch (days.length) {
        case 1:
          days.unshift(0)
          days.unshift(0)
          break
        case 2:
          days.unshift(0)
          break
      }
      this.days = days.map((item) => {
        return Number(item)
      })
    },
    // 项目报考信息列表
    selectPcList() {
      const data = {
        kind: this.pKind
      }
      selectPcList(data)
        .then((res) => {
          this.menuList = res.data
        })
        .then(() => {
          this.defaultDisplay(this.menuList)
        })
    },

    defaultDisplay(menuList) {
      this.detail = menuList.forEach((item) => {
        if (item.menuList && item.menuList.length > 0) {

          this.defaultDisplay(item.menuList)
        } else {
          if (item.menuGrade == 2) {
            this.leftTag.push(item)
          }
        }
      })
    },

    // 专业列表
    selectZyList() {
      selectZyList(undefined, this.pKind).then((res) => {
        this.zyList = res.data
      })
    },

    // 题库跳转
    goTakeAnExam(item) {
      let routeUrl
      switch (this.entrantsType) {
        case 1:
          routeUrl = this.$router.resolve({
            path: '/quesTion/quesTionListsixth',
            query: {
              type: item.value
            }
          })
          break
        case 2:
          routeUrl = this.$router.resolve({
            path: '/quesTion/quesTionList',
            query: {
              type: item.value
            }
          })
          break
        case 3:
          routeUrl = this.$router.resolve({
            path: '/seventhPage/quesTionList',
            query: {
              type: item.value
            }
          })
          break
        case 5:
          routeUrl = this.$router.resolve({
            path: '/partnerPage/quesTionList',
            query: {
              type: item.value
            }
          })
          break
      }
      window.open(routeUrl.href, '_blank')
    },

    // 资料跳转
    goMaterial(item) {
      let routeUrl
      switch (this.entrantsType) {
        case 1:
          routeUrl = this.$router.resolve({
            path: '/data/dataPackagesixth',
            query: {
              type: item.value
            }
          })
          break
        case 2:
          routeUrl = this.$router.resolve({
            path: '/data/dataPackage',
            query: {
              type: item.value
            }
          })
          break
        case 3:
          routeUrl = this.$router.resolve({
            path: '/seventhPage/dataPackage',
            query: {
              type: item.value
            }
          })
          break
        case 5:
          routeUrl = this.$router.resolve({
            path: '/partnerPage/dataPackage',
            query: {
              type: item.value
            }
          })
          break
      }
      window.open(routeUrl.href, '_blank')
    },

    // 点击菜单跳转
    goPracticalInformation(i, item) {
      const routeUrl = this.$router.resolve({
        path: '/projectChannelPage/menuContent',
        query: {
          kind: this.pKind,
          menuOneId: i ? i.id : item.parentId,
          menuTwoId: item ? item.id : null
        }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: YouSheBiaoTiHei;
  /* 常规 */
  src: url("~@/assets/fontFamily/YouSheBiaoTiHei-2.ttf");
  font-weight: normal;
  font-style: normal;
}

.layout_box {
  width: 1219px;
  margin: auto;
  padding-top: 21px;
  padding-bottom: 26px;

  .projectTag_box {
    display: flex;
    width: 100%;
    height: 74px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 23px;

    .tag_backImg_box {
      background-image: url("~@/assets/img/projectChannelPage/projectTagBackImg.png");
      width: 190px;
      margin-right: 22px;
      display: flex;
      align-items: center;

      .name {
        width: 111px;
        color: #ffffff;
        font-size: 20px;
        margin-left: 20px;
        font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
      }
    }

    .tag_box {
      display: flex;
      font-size: 14px;
      color: #777777;
      padding: 14px 0 13px 0;

      .line {
        margin: 10px 35px;
        height: 28px;
      }

      .left_tag {
        width: 317px;
        display: flex;
        /* justify-content: space-around; */
        flex-wrap: wrap;

        .tag_item {
          width: 80px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .name_box {
            width: 80px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .icon {
            margin-right: 12px;
            width: 12px;
            height: 12px;
          }
        }

        .m-r-38 {
          margin-right: 38px;
        }
      }

      .right_tag {
        width: 198px;
        display: flex;
        flex-wrap: wrap;

        .tag_item {
          display: flex;
          align-items: center;
          cursor: pointer;

          .icon {
            margin-right: 12px;
            width: 12px;
            height: 12px;
          }
        }

        .m-r-38 {
          margin-right: 38px;
        }
      }
    }
  }

  .material {
    height: 324px !important;

    .content {
      height: 200px !important;
    }
  }
}

.table {
  ::v-deep th {
    background-color: rgba(241, 241, 241, 0.38);
    /* opacity: 0.38; */
    color: #484848;
    font-size: 22px;
    font-weight: 600;
  }

  ::v-deep .el-table--border {
    border-radius: 9px;
  }
}
</style>
