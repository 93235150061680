<template>
  <div>
    <div class="project_main">
      <div class="project_left">
        <div class="img_box">
          <el-carousel class="banner" height="335px">
            <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
              <img class="img" :src="item.imgUrl" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="course_item" :class="index == 0 || index == 3 ? '' : 'm-r-19'" v-for="(item, index) in classList"
          :key="index">
          <div class="item_box">
            <img class="item_img" :src="item.img" @click="imgxq(index, item)" />
            <div class="content_box">
              <div class="course_name">
                {{ item.tiitle }}
              </div>
              <div class="tag_item">
                <div>#专属服务 · 极速答疑 · 督学跟踪</div>
                <el-divider direction="vertical"></el-divider>
                <div class="black">{{ item.num }}门课程</div>
              </div>
              <el-divider class="line"></el-divider>
              <div class="price_box">
                <div class="price">
                  <span>￥</span>
                  <span>{{ item.price }}</span>
                </div>
                <div class="people_num">人气值{{ item.clicks }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="exam_countdown">
        <div class="title" v-if="days.length > 0">
          距离{{ projectDetails.examTime | format('yyyy') }}年{{
            projectDetails.pname
          }}考试还有
        </div>
        <div class="remaining" v-if="days.length > 0">
          <div class="day_box">
            <div class="day_back"></div>
            <span>{{ days[0] }}</span>
          </div>
          <div class="day_box">
            <div class="day_back"></div>
            <span>{{ days[1] }}</span>
          </div>
          <div class="day_box">
            <div class="day_back"></div>
            <span>{{ days[2] }}</span>
          </div>
          <span class="day_text">天</span>
        </div>
        <div class="menu_box">
          <div class="menu_item" v-for="(i, index) in menuList" :key="index" @click="goPracticalInformation(i, null)">
            <div class="left_line_box" :class="index == 0 ? 'm-t-6' : 'p-t-6'">
              <div class="round"></div>
              <div class="line" v-if="index + 1 < menuList.length"></div>
            </div>
            <div class="right_content_box">
              <div class="title_box" :class="index == 0 ? '' : 'm-t-12'">
                <div class="title">
                  <span class="icon_item" :class="icon[index].img"></span>
                  <span>{{ i.menuName }}</span>
                </div>
                <img class="img" src="@/assets/img/projectChannelPage/menuBackImg.png" alt="" />
              </div>
              <div class="content_box">
                <div class="content_item" :class="(index + 1) % 3 === 0 ? '' : 'm-r-7'"
                  v-for="(item, index) in i.menuList" :key="index" @click="goPracticalInformation(i, item)">
                  {{ item.menuName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectImgList, selectPageListToIndex } from '@/api/home.js'
export default {
  props: ['zyList', 'projectDetails', 'menuList', 'days', 'pKind', 'icon'],
  data() {
    return {
      bannerImg: [],
      classList: []
    }
  },
  created() {
    this.projectDetails.examTime
    this.selectImgList()
    this.getClassList()
  },
  mounted() {

  },
  methods: {
    // 轮播图
    selectImgList() {
      let data = {
        kindId: this.pKind
      }
      selectImgList(data).then((res) => {
        this.bannerImg = res.data
      })
    },
    // 知识套餐列表
    getClassList() {
      selectPageListToIndex(3, 1, undefined, this.pKind).then((res) => {
        this.classList = res.rows
      })
    },
    // 项目下套餐详情跳转
    imgxq(index, item) {
      let routeUrl = this.$router.resolve({
        name: 'VIP专属班详情',
        query: {
          id: item.id,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice
        }
      })
      window.open(routeUrl.href, '_blank')
    },

    // 点击菜单跳转
    goPracticalInformation(i, item) {
      let routeUrl = this.$router.resolve({
        path: '/projectChannelPage/menuContent',
        query: {
          kind: this.pKind,
          menuOneId: i ? i.id : item.parentId,
          menuTwoId: item ? item.id : null
        }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.project_main {
  height: 690px;
  display: flex;

  .project_left {
    display: flex;
    flex-wrap: wrap;
    width: 905px;

    .img_box {
      background-color: #ffffff;
      width: 594px;
      height: 335px;
      border-radius: 8px;
      margin-right: 23px;
      margin-bottom: 20px;

      .banner {
        border-radius: 8px;
      }

      .img {
        max-width: 594px;
        max-height: 335px;
      }
    }

    .course_item {
      width: 288px;
      height: 335px;
      border-radius: 8px;
      background-color: #ffffff;

      .item_box {
        .item_img {
          width: 288px;
          height: 161px;
          border-radius: 8px;
        }

        .content_box {
          height: 174px;
          padding: 17px 17px 13px 16px;

          .course_name {
            width: 255px;
            height: 48px;
            margin-bottom: 28px;
            color: #333333;
            line-height: 24px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .tag_item {
            color: #b8681e;
            font-size: 12px;
            display: flex;
            align-items: center;

            .black {
              color: #333333 !important;
            }
          }

          .line {
            margin: 12px 0 17px 0;
          }

          .price_box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .price {
              color: #ff5e51;
              font-size: 20px;
            }

            .people_num {
              color: #999999;
              font-size: 14px;
            }
          }
        }
      }
    }

    .m-r-19 {
      margin-right: 19px;
    }
  }

  .exam_countdown {
    width: 298px;
    height: 690px;
    background-color: #ffffff;
    border-radius: 11px;
    margin-left: 16px;
    padding: 24px 21px 32px 14px;
    overflow-x: hidden;
    overflow-y: auto;

    .title {
      color: #484848;
      line-height: 18px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 25px;
    }

    .remaining {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 26px;

      .day_box {
        width: 38px;
        height: 65px;
        border-radius: 4px;
        border: 1px #97aecc solid;
        margin-right: 6px;
        position: relative;
        color: #4a6af0;
        font-size: 37px;
        font-weight: bold;
        text-align: center;
        line-height: 65px;

        .day_back {
          width: 36px;
          height: 32px;
          background: linear-gradient(180deg,
              rgba(255, 255, 255, 0.33) 0%,
              rgba(128, 128, 128, 0.05) 100%);
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 4px 4px 0 0;
        }
      }

      .day_text {
        font-weight: bold;
        color: #484848;
        font-size: 18px;
      }
    }

    .menu_box {
      height: 500px;
      width: 273px;
      overflow: auto;

      .menu_item {
        display: flex;

        .left_line_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 13px;
          margin-top: 10px;

          .round {
            width: 12px;
            height: 12px;
            background-color: #4a6af0;
            border-radius: 6px;
            opacity: 0.4;
            margin-bottom: 4px;
          }

          .line {
            width: 2px;
            height: 80%;
            background-color: #4a6af0;
            opacity: 0.2;
          }
        }

        .p-t-6 {
          padding-top: 6px;
        }

        .m-t-6 {
          margin-top: 6px;
        }

        .right_content_box {
          .title_box {
            display: flex;
            height: 22px;
            margin-bottom: 20px;

            .title {
              cursor: pointer;
              padding: 0 7px;
              background-color: #4a6af0;
              min-width: 56px;
              max-width: 200px;
              height: 22px;
              border-radius: 4px 0 0 4px;
              opacity: 0.7;
              margin-right: 1px;
              margin-bottom: 0;
              color: #ffffff;
              font-size: 12px;
              font-weight: 600;
              line-height: 22px;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              .icon_item {
                color: #ffffff;
                margin-right: 5px;
              }
            }

            .m-t-12 {
              margin-top: 12px;
            }

            .img {
              width: 30px;
            }
          }

          .content_box {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            .content_item {
              padding: 0 13px;
              width: 75px;
              overflow: hidden;
              height: 28px;
              border-radius: 20px;
              background: linear-gradient(180deg, #f2fafd 0%, #d8effd 100%);
              margin-bottom: 12px;
              color: #4a6af0;
              font-size: 12px;
              text-align: center;
              line-height: 28px;
              cursor: pointer;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .m-r-7 {
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}</style>
