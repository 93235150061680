<template>
  <div v-if="show">
    <div class="screen_box">
      <div class="title">班型对比</div>
      <div class="filter" :class="filterSwitch ? 'open' : ''" @click="openFilter">
        <img class="img" src="@/assets/img/projectChannelPage/filter.png" alt="" />
        <span v-if="!zyName">专业筛选</span>
        <span v-else>{{ zyName }}</span>
      </div>
    </div>
    <div class="filter_box" v-show="filterSwitch">
      <div class="triangle"></div>
      <div class="province_box">
        <span class="name">选择省份</span>
        <div class="province">
          <div @click="changeProvince(item)" class="item" :class="item.lanmuID == areaId ? 'on' : ''"
            v-for="(item, index) in provinceList" :key="index">
            {{ item.lname }}
          </div>
        </div>
      </div>
      <div class="province_box">
        <span class="name">选择专业</span>
        <div class="province">
          <div @click="changeZy(item)" class="item" :class="item.zId == zyId ? 'on' : ''" v-for="item in zyList"
            :key="item.zId">
            {{ item.zName }}
          </div>
        </div>
      </div>
    </div>
    <div class="wrap">
      <div class="title_box">
        <ul class="title_img">
          <img class="img" src="@/assets/img/projectChannelPage/comparison3.png" alt="" />
          <div class="title">
            {{ detail.classOneName }}
            <div class="super">销量TOP1</div>
          </div>

          <div class="desc">全程领学督学</div>
        </ul>
        <ul class="title_img">
          <img class="img" src="@/assets/img/projectChannelPage/comparison1.png" alt="" />
          <div class="title">{{ detail.classTwoName }}</div>
          <div class="desc">班级和配套齐全</div>
        </ul>
        <ul class="title_img" v-if="detail.classThreeName">
          <img class="img" src="@/assets/img/projectChannelPage/comparison2.png" alt="" />
          <div class="title">{{ detail.classThreeName }}</div>
          <div class="desc">省时高效</div>
        </ul>
      </div>
      <div class="big_box">
        <ul class="ul_box border-r">
          <li class="fit_people">
            <span class="fit_people-title">适合人群：</span>
            <span class="fit_people-content">零基础，需要领学领练督学等高端服务</span>
          </li>
          <li class="item_li" :style="`height: ${item.height}px;`" ref="content1"
            v-for="(item, index) in object.oneData ? object.oneData : []" :key="index">
            <div class="tag">{{ item.dimensionName }}</div>
            <!-- 价格对比 -->
            <div class="price_comparison" v-if="item.data.dimensionType == 1">
              <div class="item_box" v-for="(child, childIndex) in item.data.classesList" :key="childIndex">
                <div class="course_item">
                  <div class="course-title">
                    <div class="course_name-box">{{ child.tiitle }}</div>
                    <div v-if="childIndex <= 1" class="suggest pup">超值</div>
                    <div v-else-if="childIndex >= 2 && childIndex < 4" class="suggest">
                      推荐
                    </div>
                  </div>
                  <div class="course_price-box">￥{{ child.price }}</div>
                </div>
                <div @click="apply(child)" class="btn_item">报名</div>
              </div>
              <div class="background_box yellow_back"></div>
            </div>
            <!-- 课程对比 -->
            <div v-else-if="item.data.dimensionType == 2">
              <div class="cuorse_comparison yellow" v-for="(child, childIndex) in getContent(
                item.data.courseContent
              )" :key="childIndex">
                <div class="content_box">
                  {{ child.content }}
                </div>
                <div class="tag_box yellow_tag_box">{{ child.stageName }}</div>
              </div>
            </div>
            <!-- 保障对比 -->
            <div class="safeguard" v-else-if="item.data.dimensionType == 3">
              <div v-html="ImgSizeLimits(item.data.courseContent)"></div>
              <el-button v-if="item.data.agreementList && item.data.agreementList.length > 0
                " @click="openAgreement(item)" class="btn" type="text">查看协议>></el-button>
            </div>
            <!-- 自定义 -->
            <div class="custom" v-else-if="item.data.dimensionType == 4" v-html="ImgSizeLimits(item.data.courseContent)">
              自定义
            </div>
          </li>
        </ul>
        <ul class="ul_box" :class="detail.classThreeName ? 'border-r' : ''">
          <li class="fit_people">
            <span class="fit_people-title">适合人群：</span>
            <span class="fit_people-content">基础薄弱，或首次参考</span>
          </li>
          <li class="item_li" :style="`height: ${item.height}px;`" ref="content2"
            v-for="(item, index) in object.twoData ? object.twoData : []" :key="index">
            <div class="tag">{{ item.dimensionName }}</div>
            <!-- 价格对比 -->
            <div class="price_comparison" v-if="item.data.dimensionType == 1">
              <div class="item_box" v-for="(child, childIndex) in item.data.classesList" :key="childIndex">
                <div class="course_item">
                  <div class="course-title">
                    <div class="course_name-box">{{ child.tiitle }}</div>
                    <div v-if="childIndex < 2" class="suggest">推荐</div>
                  </div>
                  <div class="course_price-box">￥{{ child.price }}</div>
                </div>
                <div @click="apply(child)" class="btn_item">报名</div>
              </div>
              <div class="background_box pink_back"></div>
            </div>
            <!-- 课程对比 -->
            <div v-else-if="item.data.dimensionType == 2">
              <div class="cuorse_comparison red" v-for="(child, childIndex) in getContent(
                item.data.courseContent
              )" :key="childIndex">
                <div class="content_box red_content_box">
                  {{ child.content }}
                </div>
                <div class="tag_box red_tag_box">{{ child.stageName }}</div>
              </div>
            </div>
            <!-- 保障对比 -->
            <div class="safeguard" v-else-if="item.data.dimensionType == 3">
              <div v-html="ImgSizeLimits(item.data.courseContent)"></div>
              <el-button v-if="item.data.agreementList && item.data.agreementList.length > 0
                " @click="openAgreement(item)" class="btn" type="text">查看协议>></el-button>
            </div>
            <!-- 自定义 -->
            <div class="custom" v-else-if="item.data.dimensionType == 4" v-html="ImgSizeLimits(item.data.courseContent)">
            </div>
          </li>
        </ul>
        <ul class="ul_box" v-if="detail.classThreeName">
          <li class="fit_people">
            <span class="fit_people-title">适合人群：</span>
            <span class="fit_people-content">基础较好，或参加过考试</span>
          </li>
          <li class="item_li" :style="`height: ${item.height}px;`" ref="content3"
            v-for="(item, index) in object.ThreeData ? object.ThreeData : []" :key="index">
            <div class="tag">{{ item.dimensionName }}</div>
            <!-- 价格对比 -->
            <div class="price_comparison" v-if="item.data.dimensionType == 1">
              <div class="item_box" v-for="(child, childIndex) in item.data.classesList" :key="childIndex">
                <div class="course_item">
                  <div class="course_name-box">{{ child.tiitle }}</div>
                  <div class="course_price-box">￥{{ child.price }}</div>
                </div>
                <div @click="apply(child)" class="btn_item">报名</div>
              </div>
              <div class="background_box blue_back"></div>
            </div>
            <!-- 课程对比 -->
            <div v-else-if="item.data.dimensionType == 2">
              <div class="cuorse_comparison blue" v-for="(child, childIndex) in getContent(
                item.data.courseContent
              )" :key="childIndex">
                <div class="content_box">
                  {{ child.content }}
                </div>
                <div class="tag_box blue_tag_box">{{ child.stageName }}</div>
              </div>
            </div>
            <!-- 保障对比 -->
            <div class="safeguard" v-else-if="item.data.dimensionType == 3">
              <div v-html="ImgSizeLimits(item.data.courseContent)"></div>
              <el-button v-if="item.data.agreementList && item.data.agreementList.length > 0
                " @click="openAgreement(item)" class="btn" type="text">查看协议>></el-button>
            </div>
            <!-- 自定义 -->
            <div class="custom" v-else-if="item.data.dimensionType == 4" v-html="ImgSizeLimits(item.data.courseContent)">
            </div>
          </li>
        </ul>
      </div>

      <el-dialog title="查看协议" :visible.sync="agreementVisible" width="978px" :before-close="agreementClose"
        class="checkAgreement">
        <div v-for="(item, index) in agreementList" :key="index" v-html="item.content"></div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  selectClassContrastVoPc,
  getTenantKcProvinceList,
  selectOptionList,
  selectOptionZyList,
} from "@/api/home.js";
import kindChooseViewk from "@/components/kindChooseViewktypevip.vue";
export default {
  props: ["pKind", "schoolId"],
  components: {
    kindChooseViewk,
  },
  data() {
    return {
      content1: null,
      content2: null,
      content3: null,
      detail: {},
      object: {},
      agreementVisible: false,
      agreementList: [],
      provinceList: [],
      zyList: [],
      allZyList: [],
      zyName: "",
      areaId: "",
      zyId: "",
      filterSwitch: false,
      show: true,
    };
  },
  async created() {
    await this.selectProvinceVoList();
    this.getData();
  },
  methods: {
    getData() {
      let data = {
        pageSize: 1,
        pageNum: 1,
        kindId: this.pKind,
        areaId: this.areaId ? this.areaId : null,
        zyId: this.zyId ? this.zyId : null,
      };
      selectClassContrastVoPc(data).then((res) => {
        if (!res.rows.length) {
          this.detail = [];
          this.object = [];
          this.show = false
          return;
        }
        this.detail = res.rows[0];
        this.object = res.rows[0].object;
        this.$nextTick(() => {
          this.content1 = this.$refs.content1;
          this.content2 = this.$refs.content2;
          this.content3 = this.$refs.content3;
          for (let i = 0; i < this.content1.length; i++) {
            this.content1[i].style.height = "auto";
            this.content2[i].style.height = "auto";
            this.content3 && this.content3.length > 0
              ? (this.content3[i].style.height = "auto")
              : "";
          }
          this.$nextTick(() => {
            setTimeout(() => {
              for (let i = 0; i < this.content1.length; i++) {
                if (this.content1[i]) {
                  let height1 = Number(this.content1[i].offsetHeight);
                  let height2 = Number(this.content2[i].offsetHeight);
                  let height3 = Number(
                    this.content3 && this.content3.length > 0 ? this.content3[i].offsetHeight : 0
                  );
                  this.$set(
                    this.object.oneData[i],
                    "height",
                    Math.max(height1, height2, height3)
                  );
                  this.$set(
                    this.object.twoData[i],
                    "height",
                    Math.max(height1, height2, height3)
                  );
                  if (this.object.ThreeData) {
                    this.$set(
                      this.object.ThreeData[i],
                      "height",
                      Math.max(height1, height2, height3)
                    );
                  }
                }
              }
            }, 100)
          })
        });
      });
    },
    agreementClose() {
      this.agreementList = [];
      this.agreementVisible = false;
    },
    openAgreement(item) {

      this.agreementList = item.data.agreementList;
      this.agreementVisible = true;
    },
    apply(item) {

      let routeUrl;
      if (item.productLine == "3") {
        routeUrl = this.$router.resolve({
          path: "/VIP/VIPClassxq",
          query: {
            id: item.id,
          },
        });
      } else {
        routeUrl = this.$router.resolve({
          path: "/typeclassxq",
          query: {
            id: item.id,
          },
        });
      }
      window.open(routeUrl.href, "_blank");
    },
    openFilter() {
      this.filterSwitch = !this.filterSwitch;
    },
    getContent(content) {
      let list = JSON.parse(content);
      return list.filter((d) => {
        return d.content && d.stageName;
      });
    },

    /* 省份列表 */
    async selectProvinceVoList() {
      let req = {
        kindId: this.pKind,
      };
      await selectOptionList(req).then((res) => {
        this.provinceList = res.data.area || [];
        this.areaId =
          (this.provinceList[0] && this.provinceList[0].lanmuID) || "";
      });
      await this.selectOptionZyList();
    },
    // 专业列表
    async selectOptionZyList() {
      let req = {
        kindId: this.pKind,
        areaId: this.areaId,
      };
      await selectOptionZyList(req).then((res) => {
        this.zyList = res.data.zhuanye || [];
        this.zyId = (this.zyList[0] && this.zyList[0].zId) || "";
      });
    },
    async changeProvince(item) {
      this.areaId = item.lanmuID;
      await this.selectOptionZyList();
      this.getData();
    },
    changeZy(item) {
      this.zyId = item.zId;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
@op10: 1;
@op9: 0.9;
@op8: 0.8;
@op7: 0.7;
@op6: 0.6;

.screen_box {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .title {
    color: #484848;
    font-size: 24px;
    font-weight: 600;
    // margin-bottom: 16px;
  }

  .filter {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 147px;
    height: 35px;
    border: 1px solid #4a6af0;
    border-radius: 21px;
    color: #4a6af0;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    padding: 0 10px;

    .img {
      margin-right: 16px;
    }
  }

  .open {
    background-color: rgba(74, 106, 240, 0.22);
  }
}

.filter_box {
  width: 1219px;
  min-height: 126px;
  border-radius: 7px;
  padding: 15px 25px;
  margin-bottom: 24px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.09);
  position: relative;

  .triangle {
    width: 16px;
    border-right: 10px solid transparent;
    border-top: 14px solid #ffffff;
    border-bottom: 19px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    transform: rotate(180deg);
    top: -32px;
    right: 50px;
  }

  .province_box {
    display: flex;

    .name {
      color: #666666;
      font-size: 18px;
      line-height: 34px;
      margin-right: 22px;
      flex-shrink: 0;
    }

    .province {
      display: flex;
      flex-wrap: wrap;

      .item {
        min-width: 56px;
        height: 34px;
        padding: 0 10px;
        line-height: 34px;
        margin-right: 16px;
        margin-bottom: 14px;
        border-radius: 5px;
        font-size: 18px;
        cursor: pointer;
      }

      .on {
        border: 1px solid #4a6af0;
        color: #4a6af0;
        background-color: #ebefff;
        box-sizing: border-box;
      }
    }
  }
}

.wrap {
  width: 1219px;
  background-color: #ffffff;
  border-radius: 14px;

  .title_box {
    display: flex;
    justify-content: space-around;
    position: sticky;
    top: 0;
    z-index: 3;

    .title_img {
      flex: 1;
      position: relative;

      .img {
        width: 100%;
      }

      >.title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
        font-family: YouSheBiaoTiHei;
        font-size: 31px;
        color: #ffffff;

        .super {
          position: absolute;
          right: -80px;
          top: 0;
          width: 68px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
          color: #999999;
          background: #fff;
          border-radius: 10px 10px 10px 0;
          font-family: PingFang SC-Regular, PingFang SC;
        }
      }

      >.desc {
        position: absolute;
        left: 50%;
        bottom: 7px;
        font-size: 12px;
        color: #fff;
        transform: translateX(-50%) scale(0.9);
      }
    }
  }

  .big_box {
    display: flex;
    justify-content: space-around;

    // overflow: scroll;
    >ul {
      width: 406px;

      >li {
        border-bottom: 1px solid #dedede;
        position: relative;
      }
    }

    .ul_box {
      flex: 1;
      word-wrap: break-word;

      .fit_people {
        padding-left: 21px;
        height: 56px;
        line-height: 56px;

        .fit_people-title {
          font-size: 14px;
          color: #333333;
        }

        .fit_people-content {
          font-weight: bold;
          color: #333333;
          font-size: 14px;
        }
      }

      .item_li {
        .tag {
          display: inline-block;
          padding: 0 13px 0 10px;
          height: 32px;
          background-color: #eceef7;
          border-radius: 0 0 13px 0;
          color: #333333;
          text-align: center;
          line-height: 32px;
          margin-bottom: 21px;
          font-weight: bold;
        }

        // 课程对比
        .cuorse_comparison {
          // height: 59px;
          margin-left: 14px;
          margin-bottom: 18px;
          margin-right: 18px;
          border-radius: 8px;
          display: flex;

          .content_box {
            flex: 1;
            // height: 59px;
            border-radius: 8px 0px 0px 8px;
            font-size: 14px;
            font-weight: 550;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #444444;
            padding-left: 20px;
          }

          .tag_box {
            width: 59px;
            padding: 8px 13px;
            border-radius: 0px 8px 8px 8px;
            color: #ffffff;
            // display: flex;
            // justify-content: center;
            // align-items: center;
          }

          // 协议
          &.yellow {
            background: rgba(255, 242, 213, @op6);

            .yellow_tag_box {
              background: linear-gradient(105deg,
                  rgba(255, 219, 162, @op6) 0%,
                  rgba(255, 176, 86, @op6) 100%);
            }

            &:nth-last-child(1) {
              background: rgba(255, 242, 213, @op10);

              .yellow_tag_box {
                background: linear-gradient(105deg,
                    rgba(255, 219, 162, @op10) 0%,
                    rgba(255, 176, 86, @op10) 100%);
              }
            }

            &:nth-last-child(2) {
              background: rgba(255, 242, 213, @op9);

              .yellow_tag_box {
                background: linear-gradient(105deg,
                    rgba(255, 219, 162, @op9) 0%,
                    rgba(255, 176, 86, @op9) 100%);
              }
            }

            &:nth-last-child(3) {
              background: rgba(255, 242, 213, @op8);

              .yellow_tag_box {
                background: linear-gradient(105deg,
                    rgba(255, 219, 162, @op8) 0%,
                    rgba(255, 176, 86, @op8) 100%);
              }
            }

            &:nth-last-child(4) {
              background: rgba(255, 242, 213, @op7);

              .yellow_tag_box {
                background: linear-gradient(105deg,
                    rgba(255, 219, 162, @op7) 0%,
                    rgba(255, 176, 86, @op7) 100%);
              }
            }
          }

          &.red {
            background: rgba(252, 227, 226, @op6);

            .red_tag_box {
              background: linear-gradient(125deg,
                  rgba(242, 136, 136, @op6) 0%,
                  rgba(254, 94, 81, @op6) 100%);
            }

            &:nth-last-child(1) {
              background: rgba(252, 227, 226, @op10);

              .red_tag_box {
                background: linear-gradient(125deg,
                    rgba(242, 136, 136, @op10) 0%,
                    rgba(254, 94, 81, @op10) 100%);
              }
            }

            &:nth-last-child(2) {
              background: rgba(252, 227, 226, @op9);

              .red_tag_box {
                background: linear-gradient(125deg,
                    rgba(242, 136, 136, @op9) 0%,
                    rgba(254, 94, 81, @op9) 100%);
              }
            }

            &:nth-last-child(3) {
              background: rgba(252, 227, 226, @op8);

              .red_tag_box {
                background: linear-gradient(125deg,
                    rgba(242, 136, 136, @op8) 0%,
                    rgba(254, 94, 81, @op8) 100%);
              }
            }

            &:nth-last-child(4) {
              background: rgba(252, 227, 226, @op7);

              .red_tag_box {
                background: linear-gradient(125deg,
                    rgba(242, 136, 136, @op7) 0%,
                    rgba(254, 94, 81, @op7) 100%);
              }
            }
          }

          &.blue {
            background: rgba(235, 242, 255, @op6);

            .blue_tag_box {
              background: linear-gradient(125deg,
                  rgba(136, 177, 241, @op6) 0%,
                  rgba(99, 119, 215, @op6) 100%);
            }

            &:nth-last-child(1) {
              background: rgba(235, 242, 255, @op10);

              .blue_tag_box {
                background: linear-gradient(125deg,
                    rgba(136, 177, 241, @op10) 0%,
                    rgba(99, 119, 215, @op10) 100%);
              }
            }

            &:nth-last-child(2) {
              background: rgba(235, 242, 255, @op9);

              .blue_tag_box {
                background: linear-gradient(125deg,
                    rgba(136, 177, 241, @op9) 0%,
                    rgba(99, 119, 215, @op9) 100%);
              }
            }

            &:nth-last-child(3) {
              background: rgba(235, 242, 255, @op8);

              .blue_tag_box {
                background: linear-gradient(125deg,
                    rgba(136, 177, 241, @op8) 0%,
                    rgba(99, 119, 215, @op8) 100%);
              }
            }

            &:nth-last-child(4) {
              background: rgba(235, 242, 255, @op7);

              .blue_tag_box {
                background: linear-gradient(125deg,
                    rgba(136, 177, 241, @op7) 0%,
                    rgba(99, 119, 215, @op7) 100%);
              }
            }
          }
        }

        // 保障对比
        .safeguard {
          padding-left: 22px;
          padding-right: 18px;

          ::v-deep {
            p {
              color: #333;
              font-size: 14px;
              padding-bottom: 12px;

              img {
                display: inline-block;
              }
            }
          }

          >.btn {
            // margin-left: 70px;
            color: #657ad8;
          }

          .btn::after {
            content: " ";
            display: block;
            width: 73px;
            height: 1px;
            background-color: #657ad8;
            margin-top: 5px;
          }
        }

        // 价格对比
        .price_comparison {
          padding-left: 22px;

          .item_box {
            margin-bottom: 19px;
            display: flex;
            justify-content: space-between;
            position: relative;
            z-index: 1;

            .course_item {
              // width: 300px;
              flex: 1;
              padding-right: 8px;
              display: flex;
              justify-content: space-between;

              .course_name-box {
                max-width: 164px;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #333333;
              }

              .course-title {
                display: flex;
              }

              .suggest {
                margin-left: 8px;
                width: 41px;
                height: 20px;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                transform: scale(0.8);
                background: url("~@/assets/img/projectChannelPage/bc1.png") no-repeat center;
                background-size: 100% 100%;
                color: #fff;
              }

              .suggest.pup {
                background: url("~@/assets/img/projectChannelPage/bc2.png") no-repeat center;
                background-size: 100% 100%;
                color: #efb163;
              }

              .course_price-box {
                color: #ff0000;
              }
            }

            .btn_item {
              width: 56px;
              height: 25px;
              font-size: 14px;
              border-radius: 25px;
              line-height: 25px;
              margin-right: 12px;
              text-align: center;
              background: linear-gradient(90deg, #f28888 30%, #fe5e51 100%);
              color: #ffffff;
              cursor: pointer;
            }
          }

          .background_box {
            width: 100%;
            height: 120px;
            position: absolute;
            bottom: 0;
            left: 0;
          }

          .yellow_back {
            background: linear-gradient(180deg, transparent 0%, #f0bb71 400%);
          }

          .pink_back {
            background: linear-gradient(180deg, transparent 0%, #f28888 400%);
          }

          .blue_back {
            background: linear-gradient(180deg, transparent 0%, #87b0f1 400%);
          }
        }

        // 自定义
        .custom {
          padding-left: 22px;
          padding-right: 18px;
          margin-bottom: 31px;

          ::v-deep {
            p {
              color: #333;
              font-size: 14px;
              padding-bottom: 12px;
            }
          }
        }
      }
    }

    .border-r {
      box-sizing: border-box;
      border-right: 1px solid #eeeeee;
    }

    .ul_box:hover {
      box-shadow: 0px 0px 20px 1px rgba(68, 68, 68, 0.16);
      transition: 200ms;
    }
  }
}

::v-deep .checkAgreement {
  .el-dialog {
    border-radius: 15px;
  }

  .el-dialog__title {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
